import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'flowbite-react';
import { useBlockchain } from '../hooks/useBlockchain';

const AllowanceCheck = ({ amount, setAmount, duration, setDuration, handleAmountChange, handleNextStep, projectAddress }) => {
    const { connect, account, tokenBalance, fetchTokenBalance, checkAllowance, approveTokens, stakeTokens } = useBlockchain();
    const [totalCashout, setTotalCashout] = useState(0);
    const [totalReward, setTotalReward] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [allowanceChecked, setAllowanceChecked] = useState(false);
    const [allowanceSufficient, setAllowanceSufficient] = useState(false);

    useEffect(() => {
        let treward = (Number.parseFloat(amount.toString()) / 100) * Number.parseFloat(duration.rate.toString());
        setTotalReward(treward);
        let tcashout = Number.parseFloat(amount.toString()) + treward;
        setTotalCashout(tcashout);
    }, [amount, duration]);

    const handleCheckAllowance = async () => {
        setLoading(true);
        setError(null);
        try {
            const allowance = await checkAllowance(amount, projectAddress);
            setAllowanceSufficient(allowance);
            setAllowanceChecked(true);
            if (allowance < amount) {
                await approveTokens(amount, projectAddress);
                pollAllowance();
            } else {
                console.log(`allowance covers  to amount: ${allowance} : ${amount}`);
            }
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    };

    const pollAllowance = () => {
        const intervalId = setInterval(async () => {
            try {
                const sufficient = await checkAllowance(amount);
                if (sufficient) {
                    setAllowanceSufficient(true);
                    clearInterval(intervalId);
                    setLoading(false);
                } else {

                }
            } catch (err) {
                setError(err.message);
                clearInterval(intervalId);
                setLoading(false);
            }
        }, 3000); // Poll every 3 seconds
    };

    useEffect(() => {
        if (account) {
            handleCheckAllowance();
        }
    }, [account]);

    return (
        <div>
            <div className="flex-wrap items-center gap-2 pt-2">
                {loading ? (
                    <Spinner aria-label="Loading" />
                ) : (
                    <>
                        {error && <p className="text-red-500">{error}</p>}
                        {!allowanceChecked && <Button onClick={handleCheckAllowance}>Check Allowance</Button>}
                        {/* {error && <Button color="success" onClick={() => { handleCheckAllowance() }}>
                            Retry
                        </Button>} */}
                        {allowanceSufficient && (
                            <>
                                <p className="center text-green-500">Allowance set successfully!</p>
                                <Button.Group>
                                    <Button
                                        disabled={tokenBalance - amount < 0}
                                        onClick={() => {
                                            if (tokenBalance - amount < 0 || amount < 1000) {
                                                return;
                                            }
                                            handleNextStep();
                                        }}
                                    >
                                        Next
                                    </Button>
                                    <Button
                                        className='light'
                                        onClick={() => {
                                            handleNextStep(-1);
                                        }}
                                    >
                                        Back
                                    </Button>
                                </Button.Group>
                            </>
                        )}
                    </>
                )}
                {(tokenBalance - amount) < 0 && <p className="text-red-500">You do not have enough SCO tokens.</p>}
            </div>
        </div>
    );
};

export default AllowanceCheck;
