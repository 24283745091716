import React, { createContext, useContext, useState, useEffect } from 'react';
import { useMetaMask } from 'metamask-react';
import Web3 from 'web3';
import json from './abi.json';

const SacOneAddress = '0x85F9b1B8333BD0Cd8d10245603F4B83C4cc0212d';
const AdminContractAddress = '0x9783a65c0fb0d226128A0c30790BBDEe116eAd27';

const BlockchainContext = createContext(undefined);

export const BlockchainProvider = ({ children }) => {
    const { status, connect, account, chainId, ethereum, switchChain } = useMetaMask();

    const [web3, setWeb3] = useState(null);
    const [SacOneContract, setSacOneContract] = useState(null);
    const [AdminContract, setAdminContracts] = useState(null);
    const [tokenBalance, setTokenBalance] = useState(0);
    const [ethBalance, setEthBalance] = useState(0);
    const networks = {
        mainnet: "0x1", // 1
        // Test nets
        goerli: "0x5", // 5
        ropsten: "0x3", // 3
        rinkeby: "0x4", // 4
        kovan: "0x2a", // 42
        sepolia: "0xaa36a7", // 42
        mumbai: "0x13881", // 80001
        // Layers 2
        arbitrum: "0xa4b1", // 42161
        optimism: "0xa", // 10
        // Side chains
        polygon: "0x89", // 137
        gnosisChain: "0x64", // 100
        // Alt layer 1
        binanceSmartChain: "0x38", // 56
        avalanche: "0xa86a", // 43114
        cronos: "0x19", // 25
        fantom: "0xfa" // 250
    }
    useEffect(() => {
        const web3Instance = new Web3(ethereum);
        if (account) {
            setWeb3(web3Instance);
            setSacOneContract(new web3Instance.eth.Contract(json.sacone, SacOneAddress));
            setAdminContracts(new web3Instance.eth.Contract(json.admin, AdminContractAddress));
            setTimeout(() => {
                fetchTokenBalance();
                fetchEthBalance();
            }, 5000)
        }
    }, [account]);



    const synChain = async () => {
        if (chainId.toLowerCase() != networks.sepolia.toLowerCase()) {
            await switchChain(networks.sepolia);
        }
    }
    const fetchTokenBalance = async () => {
        if (!SacOneContract || !account) return;
        console.log("getting balance")
        const balanceWei = await SacOneContract.methods.balanceOf(account).call();
        console.log("balance", balanceWei);
        const balanceSc = web3.utils.fromWei(balanceWei, 'ether');
        setTokenBalance(balanceSc);
    };

    const fetchEthBalance = async () => {
        if (!SacOneContract || !account) return;
        console.log("getting eth balance")
        const balanceWei = await web3.eth.getBalance(account);
        console.log("balance eth", balanceWei);
        const balanceEth = web3.utils.fromWei(balanceWei, 'ether');
        setEthBalance(Number.parseFloat(balanceEth).toFixed(4));
    }
    const checkAllowance = async (amount, projectAddress) => {
        if (!account) throw new Error("No account connected");
        console.log("Checking Allowance... ", amount)
        const allowance = await SacOneContract.methods.allowance(account, projectAddress).call();
        console.log("allowance: ", Number(allowance));
        return Number(allowance);
    };

    const approveTokens = async (amount, projectAddress = AdminContractAddress) => {
        if (!account) throw new Error("No account connected");
        console.log("Approving tokens::: ", amount)
        let nAmount = web3.utils.toWei(amount, 'ether');
        console.log("Approving tokens:::::: ", nAmount)
        await SacOneContract.methods.approve(projectAddress, nAmount).send({ from: account });
    };

    const stakeTokens = async (amount, projectAddress) => {
        if (!account) throw new Error("No account connected");
        const ListingContract = new web3.eth.Contract(json.project, projectAddress)
        console.log("Staking tokens: ", amount)
        const nAmount = Web3.utils.toWei(amount, "ether")
        await ListingContract.methods.stakeTokens(nAmount).send({ from: account });
        fetchTokenBalance(); // Update balance after staking
    };

    const formatToken = (value, currency = "SCO") => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(value)
    }

    return (
        <BlockchainContext.Provider
            value={{
                web3,
                account,
                connect,
                ethereum,
                SacOneAddress,
                AdminContractAddress,
                tokenBalance,
                ethBalance,
                fetchTokenBalance,
                fetchEthBalance,
                checkAllowance,
                approveTokens,
                stakeTokens,
                formatToken,
                status,
                chainId,
                synChain
            }}
        >
            {children}
        </BlockchainContext.Provider>
    );
};

export const useBlockchain = () => {
    const context = useContext(BlockchainContext);
    if (context === undefined) {
        throw new Error('useBlockchain must be used within a BlockchainProvider');
    }
    return context;
};
