import React, { useState, useEffect } from "react";
import { Spinner } from "flowbite-react";
import { useBlockchain } from "../hooks/useBlockchain";

interface CommentType {
  id: string;
  content: string;
  userName: string;
  userAvatar: string;
  createdAt: string;
  userAddress: string;
}
const getShortAddress = (address: string) => {
  return address ? `${address.slice(0, 6)}...${address.slice(-4)}` : "";
};
const CommentsSection = ({ postId }) => {
  const { account } = useBlockchain(); // Check if the wallet is connected
  const [comments, setComments] = useState<CommentType[]>([]);
  const [loading, setLoading] = useState(true);
  const [newComment, setNewComment] = useState("");

  useEffect(() => {
    // Fetch comments from API based on postId
    const fetchComments = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://api.sacone.nma.gm/comments/project/${postId}`,
        );
        const data = await response.json();
        setComments(data);
      } catch (error) {
        console.error("Error fetching comments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchComments();
  }, [postId]);

  const handlePostComment = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    // Post new comment to the API

    /**
         * {
    "projectId":"66bca2c686fc581256d6703d",
    "userAddress":"0xA07297CEB580d35edaFb0CB2114C0A04b015FfAC",
    "content":"This project is awesome, invest while you can."
}
         */
    try {
      const response = await fetch("https://api.sacone.nma.gm/comments/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          projectId: postId,
          content: newComment,
          userAddress: account,
        }),
      });
      const newCommentData = await response.json();
      setComments([...comments, newCommentData]);
      setNewComment("");
    } catch (error) {
      console.error("Error posting comment:", error);
    }
  };

  return (
    <section className="py-8 antialiased lg:py-16">
      <div className="mx-auto px-4">
        <div className="mb-6 flex items-center justify-between">
          <h2 className="text-lg font-bold text-gray-900 dark:text-white lg:text-2xl">
            Discussion ({comments.length})
          </h2>
        </div>
        {!account ? (
          <p className="text-sm text-gray-600 dark:text-gray-400">
            Please connect your wallet to post a comment.
          </p>
        ) : (
          <form className="mb-6" onSubmit={handlePostComment}>
            <div className="mb-4 rounded-lg border border-gray-200 bg-white px-4 py-2 dark:border-gray-700 dark:bg-gray-800">
              <label htmlFor="comment" className="sr-only">
                Your comment
              </label>
              <textarea
                id="comment"
                rows={3}
                className="w-full resize-none border-0 px-0 text-sm text-gray-900 focus:outline-none focus:ring-0 dark:bg-gray-800 dark:text-white dark:placeholder:text-gray-400"
                placeholder="Write a comment..."
                required
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              ></textarea>
            </div>
            <button
              type="submit"
              className="inline-flex items-center rounded bg-blue-500 px-4 py-2.5 text-center text-xs font-medium text-white hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900"
            >
              Post comment
            </button>
          </form>
        )}
        {loading ? (
          <Spinner size="lg" />
        ) : comments.length === 0 ? (
          <p className="text-sm text-gray-600 dark:text-gray-400">
            No comments yet.
          </p>
        ) : (
          comments.map((comment) => (
            <article
              key={comment.id}
              className="mt-3 rounded-lg bg-gray-50 p-6  text-base dark:bg-gray-900"
            >
              <footer className="mb-2 flex items-center justify-between">
                <div className="flex items-center">
                  <p className="mr-3 inline-flex items-center text-sm font-semibold text-gray-900 dark:text-white">
                    {/* <img
                                            className="mr-2 w-6 h-6 rounded-full"
                                            src={comment.userAvatar || 'https://flowbite.com/docs/images/people/profile-picture-2.jpg'}
                                            alt={comment.userName}
                                        /> */}
                    {getShortAddress(comment.userAddress)}
                  </p>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    <time dateTime={comment.createdAt}>
                      {new Date(comment.createdAt).toLocaleDateString()}
                    </time>
                  </p>
                </div>
              </footer>
              <p className="text-gray-500 dark:text-gray-400">
                {comment.content}
              </p>
              {/* <div className="flex items-center mt-4 space-x-4">
                                <button
                                    type="button"
                                    className="flex items-center text-sm text-gray-500 hover:underline dark:text-gray-400 font-medium"
                                >
                                    <svg
                                        className="mr-1.5 w-3.5 h-3.5"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 20 18"
                                    >
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M5 5h5M5 8h2m6-3h2m-5 3h6m2-7H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"
                                        />
                                    </svg>
                                    Reply
                                </button>
                            </div> */}
            </article>
          ))
        )}
      </div>
    </section>
  );
};

export default CommentsSection;
