import React from "react";
import { NavLink } from "react-router-dom";
import { DarkThemeToggle, Navbar, Button } from "flowbite-react";
import ConnectWalletButton from "./ConnectWallet";
import { useBlockchain } from "../hooks/useBlockchain";

const TopNavbar = () => {
  const { account, connect, getAllProjects } = useBlockchain();

  return (
    <Navbar fluid rounded className="fixed top-0 z-50 w-full">
      <Navbar.Brand href="#">
        {/* <img src="/favicon.svg" className="mr-3 h-6 sm:h-9" alt="Flowbite React Logo" /> */}
        <DarkThemeToggle className="mr-6 focus:ring-0" />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          SacOne Dapp
        </span>
      </Navbar.Brand>
      <div className="flex md:order-2">
        <ConnectWalletButton />
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <NavLink
          to="/market"
          className={({ isActive }) =>
            isActive
              ? "text-blue-600 dark:text-blue-500"
              : "text-gray-500 dark:text-gray-400"
          }
        >
          Market
        </NavLink>
        {account && (
          <NavLink
            to="/account"
            className={({ isActive }) =>
              isActive
                ? "text-blue-600 dark:text-blue-500"
                : "text-gray-500 dark:text-gray-400"
            }
          >
            Account
          </NavLink>
        )}
        <NavLink
          to="/community"
          className={({ isActive }) =>
            isActive
              ? "text-blue-600 dark:text-blue-500"
              : "text-gray-500 dark:text-gray-400"
          }
        >
          Community
        </NavLink>{" "}
        <NavLink
          to="/docs"
          className={({ isActive }) =>
            isActive
              ? "text-blue-600 dark:text-blue-500"
              : "text-gray-500 dark:text-gray-400"
          }
        >
          Guide
        </NavLink>
        <NavLink
          to="/about"
          className={({ isActive }) =>
            isActive
              ? "text-blue-600 dark:text-blue-500"
              : "text-gray-500 dark:text-gray-400"
          }
        >
          Contact
        </NavLink>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default TopNavbar;
