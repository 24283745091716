"use client";
import React from "react";
// import { Button, Drawer } from "flowbite-react";
// import { useState } from "react";
// import { Card } from "flowbite-react";
import downloadMetamask from "../assets/downloadmetamask.png";
import addingToChrome from "../assets/addingtochrome.png";
import pinningToChrome from "../assets/pinningtotoolbar.png";

const DocumentationPage = () => {
  return (
    <div className="mx-auto w-full p-6">
      <Header />
      <Content />
    </div>
  );
};

const Header = () => (
  <header className="py-6 text-center">
    <h1 className="text-4xl font-bold  dark:text-white">Application Guide</h1>
  </header>
);

const Content = () => (
  <div className="w-full space-y-6">
    <Section
      title="1. Download Metamask"
      description="Navigate to Metamask website and download the extension for chrome or any other browser of your choice"
      imageSrc={downloadMetamask}
    />
    <Section
      title="2. Add Matamask extension"
      description="This section explains how to use the dashboard, with step-by-step instructions."
      imageSrc={addingToChrome}
    />
    <Section
      title="3. Pin Metamask for easy reach"
      description="This section explains how to use the dashboard, with step-by-step instructions."
      imageSrc={pinningToChrome}
    />
    {/* Add more sections as needed */}
  </div>
);

const Section = ({ title, description, imageSrc }) => (
  // <section className="w-full bg-white dark:bg-gray-900">
  //   <img className="w-full dark:hidden" src={imageSrc} alt="dashboard image" />
  //   <img
  //     className="hidden w-full dark:block"
  //     src={imageSrc}
  //     alt="dashboard image"
  //   />
  //   <div className="mx-auto max-w-screen-xl items-center gap-8 px-4 py-8 sm:py-16 md:grid md:grid-cols-2 lg:px-6 xl:gap-16">
  //     <div className="mt-4 md:mt-0">
  //       <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">
  //         {title}
  //       </h2>
  //       <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">
  //         {description}
  //       </p>
  //       <a
  //         href="#"
  //         className="bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 dark:focus:ring-primary-900 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-4"
  //       >
  //         Get started
  //         <svg
  //           className="-mr-1 ml-2 size-5"
  //           fill="currentColor"
  //           viewBox="0 0 20 20"
  //           xmlns="http://www.w3.org/2000/svg"
  //         >
  //           <path
  //             fill-rule="evenodd"
  //             d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
  //             clip-rule="evenodd"
  //           ></path>
  //         </svg>
  //       </a>
  //     </div>
  //   </div>
  // </section>

  <section className="w-full  py-8  sm:py-16">
    <div className="container mx-auto flex flex-col items-center gap-8 px-4 md:flex-row lg:px-6">
      {/* Image on the left */}
      <div className="md:w-1/2 ">
        <img className="w-full  shadow-xl" src={imageSrc} alt="dashboard image" />
      </div>
      {/* Text content on the right */}
      <div className="md:w-1/2">
        <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">
          {title}
        </h2>
        <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">
          {description}
        </p>
        <a
          href="#"
          className="bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 dark:focus:ring-primary-900 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-4"
        >
          Read More
          <svg
            className="-mr-1 ml-2 size-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </a>
      </div>
    </div>
  </section>
);

export default DocumentationPage;
