import React, { useEffect, useState } from "react";
import ProjectCard from "../components/ProjectCard";
import { useBlockchain } from "../hooks/useBlockchain";
import { useParams } from "react-router-dom";
import Web3 from "web3";
import StakeModal from "./../components/StakeModal";
import CommentsSection from "../components/CommentSection";
import CopyLinkButton from "../components/CopyLink";
import { Table } from "flowbite-react";

const fetchProjectsFromAPI = async () => {
  // Replace with actual API call
  const response = await fetch("https://api.sacone.nma.gm/projects");
  const projectData = await response.json();
  return projectData;
};

interface ProjectProps {
  _id: string;
  stakeId: string;
  owner: string;
  address: string;
  description: string;
  amountStaked: number;
  totalCashout: number;
  duration: string;
  stakeTime: number;
  name: string;
  amountStored: number;
  holders: number;
  projectId: string;
  projectImages: string[];
  minStake: number;
  status: boolean;
}

const ProjectPage: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Extract the id from the URL
  const {
    connect,
    account,
    tokenBalance,
    fetchTokenBalance,
    checkAllowance,
    approveTokens,
    stakeTokens,
    formatToken,
  } = useBlockchain();
  const [project, setProject] = useState<ProjectProps>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [mock] = useState<boolean>(true); // Set this to false to call the actual API

  const fetchProjectById = async (id: string) => {
    // const response = await fetch(`https://api.sacone.nma.gm/projects/${id}`);
    const response = await fetch(`https://api.sacone.nma.gm/projects/${id}`);
    const projectData = await response.json();
    console.log(project);
    return projectData;
  };

  useEffect(() => {
    setLoading(true);
    fetchProjectById(id)
      .then((p: ProjectProps) => {
        console.log("project:::", p);
        setProject(p);
        setLoading(false);
        setError(undefined);
      })
      .catch((e) => {
        setError("Error getting project");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <>
      {loading && <>Loading Data</>}
      {project && (
        <section className="">
          <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8 xl:gap-16">
              <div className="mx-auto max-w-md shrink-0 lg:max-w-lg">
                {/* <img className="w-full dark:hidden" src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front.svg" alt="" /> */}
                <img
                  className="w-full dark:block"
                  src={`https://api.sacone.nma.gm/projects/image/${project["images"][0]}`}
                  alt=""
                />
              </div>

              <div className="mt-6 sm:mt-8 lg:mt-0">
                <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                  {project?.name}
                </h1>
                <div className="mt-4 sm:flex sm:items-center sm:gap-4">
                  <p className="text-2xl font-extrabold text-gray-900 dark:text-white sm:text-3xl">
                    {formatToken(
                      Web3.utils.fromWei(project?.amountStored, "ether"),
                    )}
                  </p>

                  <div className="mt-2 flex items-center gap-2 sm:mt-0">
                    <div className="flex items-center gap-1">
                      <svg
                        className="size-4 text-yellow-300"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                      </svg>
                      <svg
                        className="size-4 text-yellow-300"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                      </svg>
                      <svg
                        className="size-4 text-yellow-300"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                      </svg>
                      <svg
                        className="size-4 text-yellow-300"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                      </svg>
                      <svg
                        className="size-4 text-yellow-300"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                      </svg>
                    </div>
                    <p className="text-sm font-medium leading-none text-gray-500 dark:text-gray-400">
                      (5.0)
                    </p>
                    <a
                      href="#"
                      className="text-sm font-medium leading-none text-gray-900 underline hover:no-underline dark:text-white"
                    >
                      345 Reviews
                    </a>
                  </div>
                </div>

                <div className="mt-6 sm:mt-8 sm:flex sm:items-center sm:gap-4">
                  {/* <a
                                    href="#"
                                    title=""
                                    className="flex items-center justify-center py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                    role="button"
                                >
                                    <svg
                                        className="w-5 h-5 -ms-2 me-2"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M12.01 6.001C6.5 1 1 8 5.782 13.001L12.011 20l6.23-7C23 8 17.5 1 12.01 6.002Z"
                                        />
                                    </svg>
                                    Add to favorites
                                </a> */}
                  <StakeModal projectAddress={project.address} />

                  <CopyLinkButton
                    link={`https://sacone.nma.gm/project/${project._id}`}
                  />
                  {/* <a
                                    href="https://twitter.com/intent/tweet?url=YOUR_URL&text=YOUR_TEXT"
                                    target='_blank'
                                    title=""
                                    className="text-white mt-4 sm:mt-0 bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800 flex items-center justify-center"
                                    role="button"
                                >
                                    <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path fill="currentColor" fill-rule="evenodd" d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z" clip-rule="evenodd" />
                                    </svg>

                                </a>
                                <a
                                    href="https://twitter.com/intent/tweet?url=YOUR_URL&text=YOUR_TEXT"
                                    target='_blank'
                                    title=""
                                    className="text-white mt-4 sm:mt-0 bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800 flex items-center justify-center"
                                    role="button"
                                >
                                    <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                        <path fill-rule="evenodd" d="M22 5.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.343 8.343 0 0 1-2.605.981A4.13 4.13 0 0 0 15.85 4a4.068 4.068 0 0 0-4.1 4.038c0 .31.035.618.105.919A11.705 11.705 0 0 1 3.4 4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 6.1 13.635a4.192 4.192 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 2 18.184 11.732 11.732 0 0 0 8.291 20 11.502 11.502 0 0 0 19.964 8.5c0-.177 0-.349-.012-.523A8.143 8.143 0 0 0 22 5.892Z" clip-rule="evenodd" />
                                    </svg>

                                </a>
                                <a
                                    href="https://www.facebook.com/sharer/sharer.php?u=YOUR_UR"
                                    target='_blank'
                                    title=""
                                    className="text-white mt-4 sm:mt-0 bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800 flex items-center justify-center"
                                    role="button"
                                >
                                    <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                        <path fill-rule="evenodd" d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z" clip-rule="evenodd" />
                                        <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
                                    </svg>


                                </a> */}
                </div>
                <div className="overflow-x-auto py-4">
                  <Table hoverable>
                    <Table.Head>
                      <Table.HeadCell>Minimum Stake</Table.HeadCell>
                      <Table.HeadCell>{formatToken(1)}</Table.HeadCell>
                    </Table.Head>
                    <Table.Head>
                      <Table.HeadCell>Minimum Lockup Period</Table.HeadCell>
                      <Table.HeadCell>90 days</Table.HeadCell>
                    </Table.Head>
                    <Table.Head>
                      <Table.HeadCell>Total Holders</Table.HeadCell>
                      <Table.HeadCell>{project.holders} ppl</Table.HeadCell>
                    </Table.Head>
                  </Table>
                </div>
                <a
                  href={`https://sepolia.etherscan.io/address/${project.address}`}
                  target="_blank"
                  className="my-2 flex items-center rounded bg-blue-500 p-4 text-center text-white hover:bg-blue-700"
                  rel="noreferrer"
                >
                  <svg
                    className="mr-2 size-6 text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                  View on Etherscan
                </a>

                <hr className="mb-3 mt-4 border-gray-200 dark:border-gray-500 md:my-8" />

                <p className="mb-6 mt-[-5px] text-gray-500 dark:text-gray-400">
                  {project.description}
                </p>
              </div>
            </div>
          </div>
          <CommentsSection postId={project._id} />
        </section>
      )}
    </>
  );
};

export default ProjectPage;
