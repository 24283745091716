import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Spinner, TextInput, Label, Checkbox, Dropdown } from 'flowbite-react';
import { useBlockchain } from '../hooks/useBlockchain';
import StakeDetails from './StakeDetails';
import AllowanceCheck from './AllowanceCheck';
import StakeConfirmation from './StakeConfirmation';
import ConnectWalletButton from './ConnectWallet';

const StakeModal = ({ projectAddress }: { projectAddress: string }) => {
    const { connect, account, tokenBalance, fetchTokenBalance, checkAllowance, approveTokens, stakeTokens } = useBlockchain();
    const [openModal, setOpenModal] = useState(false);
    const [step, setStep] = useState(1);
    const [amount, setAmount] = useState(0);
    const [duration, setDuration] = useState({ duration: 90, rate: 3 });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const amountInputRef = useRef(null);

    const handleNextStep = async (st = 1) => {
        setStep(step + st);
    };

    const handleModalClose = () => {
        setOpenModal(false);
        setStep(1);
        setAmount(0);
        setError(null);
    };
    if (!account) {
        return <ConnectWalletButton />
    }
    return (
        <>
            <Button onClick={() => setOpenModal(true)}>Stake Tokens</Button>
            <Modal show={openModal} size="xl" popup onClose={handleModalClose} initialFocus={amountInputRef}>
                <Modal.Header>
                    <ol className="flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
                        <li className={"flex md:w-full items-center text-blue-600 dark:text-blue-500 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700"}>
                            <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                                {step > 1 && <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>}
                                Stake <span className="hidden sm:inline-flex sm:ms-2">Info</span>
                            </span>
                        </li>
                        <li className={"flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700" + (step >= 2 && " text-blue-600")}>
                            <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                                <span className="me-2">2</span>
                                Allowance
                            </span>
                        </li>
                        <li className={"flex items-center" + (step >= 3 && " text-blue-600")}>
                            <span className="me-2">3</span>
                            Confirmation
                        </li>
                    </ol>
                </Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        {/* <Button color="light" onClick={() => { fetchTokenBalance(); }}>refresh Balance</Button> */}
                        {step === 1 && (
                            <StakeDetails
                                amount={amount}
                                setAmount={setAmount}
                                duration={duration}
                                setDuration={setDuration}
                                handleAmountChange={(e) => setAmount(Number(e.target.value))}
                                handleNextStep={handleNextStep}
                                tokenBalance={tokenBalance}
                            />
                        )}
                        {step === 2 && (
                            <AllowanceCheck
                                amount={amount}
                                setAmount={setAmount}
                                duration={duration}
                                setDuration={setDuration}
                                handleAmountChange={(e) => setAmount(Number(e.target.value))}
                                handleNextStep={handleNextStep}
                                projectAddress={projectAddress}
                            />
                        )}
                        {step === 3 && (
                            <StakeConfirmation
                                amount={amount}
                                duration={duration}
                                projectAddress={projectAddress}
                                handleNextStep={handleNextStep}
                            />
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default StakeModal;
