import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { Button } from 'flowbite-react';
import { useBlockchain } from '../hooks/useBlockchain';
import { FaEthereum } from 'react-icons/fa';
import { GiTwoCoins } from 'react-icons/gi';

import json from './../hooks/abi.json';

const ConnectWalletButton: React.FC = () => {
    const { connect, account, ethBalance, tokenBalance, fetchTokenBalance, fetchEthBalance, checkAllowance, approveTokens, stakeTokens, synChain } = useBlockchain();

    const getShortAddress = (address: string) => {
        return address ? `${address.slice(0, 6)}...${address.slice(-4)}` : '';
    };

    const handleConnect = async () => {
        try {
            await connect();
            await synChain();
        } catch (error) {
            console.error('Connection error:', error);
        }
    };

    return (
        <>
            {account ? (
                <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
                <div className="flex items-center space-x-4">
                <svg onClick={()=>{
                    fetchTokenBalance();
                    fetchEthBalance();
                }} className="w-6 h-6 text-gray-800 dark:text-white" style={{cursor:"pointer"}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"/>
              </svg>
              
                    <div className="flex items-center space-x-2">
                        <FaEthereum className="text-gray-800 dark:text-white" />
                        <span className="font-medium text-gray-800 dark:text-white">
                            { new Intl.NumberFormat('en-US', { style: 'currency', currency: 'ETH', maximumSignificantDigits:3 }).format(ethBalance)}
                        </span>
                    </div>
                    <div className="flex items-center space-x-2">
                        <GiTwoCoins className="text-gray-800 dark:text-white" />
                        <span className="font-medium text-gray-800 dark:text-white">
                            { new Intl.NumberFormat('en-US', { style: 'currency', currency: 'SCO' }).format(tokenBalance)}

                        </span>
                    </div>
                    <div className="flex items-center space-x-2">
                        <span className="text-gray-500 dark:text-gray-400">
                            {getShortAddress(account)}
                        </span>
                    </div>
                </div>
                </div>
            ) : (
                <Button onClick={handleConnect} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Connect Wallet
                </Button>
            )}
        </>
    );
};

export default ConnectWalletButton;




// import React, { useState, useEffect } from 'react';
// import Web3 from 'web3';
// import { Button } from 'flowbite-react';
// import { useBlockchain } from '../hooks/useBlockchain';
// import { FaEthereum } from 'react-icons/fa';
// import { GiTwoCoins } from 'react-icons/gi';

// import json from './../hooks/abi.json';

// const ConnectWalletButton: React.FC = () => {
//     const { connect, account, tokenBalance, fetchTokenBalance, checkAllowance, approveTokens, stakeTokens } = useBlockchain();

//     const [web3, setWeb3] = useState<Web3 | null>(null);
//     const [ethBalance, setEthBalance] = useState<string | null>(null);
    
//     useEffect(() => {
//         if (ethereum) {
//             const web3Instance = new Web3(ethereum);
//             setWeb3(web3Instance);
//         }
//     }, [ethereum]);

//     useEffect(() => {
//         const fetchBalances = async () => {
//             if (web3 && account) {
//                 try {
//                     // Fetch ETH balance
//                     const balanceWei = await web3.eth.getBalance(account);
//                     const balanceEth = web3.utils.fromWei(balanceWei, 'ether');
//                     setEthBalance(Number.parseFloat(balanceEth).toFixed(4));

//                     // Fetch ERC-20 token balance
//                     const tokenContract = new web3.eth.Contract(json.sacone, SacOneAddress);
//                     const balance: Number = await tokenContract.methods.balanceOf(account).call() || 0;
//                     const decimals = await tokenContract.methods.decimals().call();
//                     const tokenBalanceFormatted = Number.parseFloat(balance.toString()) / (10 ** 18);
//                     setTokenBalance(tokenBalanceFormatted.toFixed(4).toString());
//                 } catch (error) {
//                     console.error('Error fetching balances:', error);
//                 }
//             }
//         };
//         fetchBalances();
//     }, [web3, account]);

//     const getShortAddress = (address: string) => {
//         return address ? `${address.slice(0, 6)}...${address.slice(-4)}` : '';
//     };

//     const handleConnect = async () => {
//         try {
//             await connect();
//         } catch (error) {
//             console.error('Connection error:', error);
//         }
//     };

//     return (
//         <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
//             {account ? (
//                 <div className="flex items-center space-x-4">
//                     <div className="flex items-center space-x-2">
//                         <FaEthereum className="text-gray-800 dark:text-white" />
//                         <span className="font-medium text-gray-800 dark:text-white">
//                             {ethBalance} ETH
//                         </span>
//                     </div>
//                     <div className="flex items-center space-x-2">
//                         <GiTwoCoins className="text-gray-800 dark:text-white" />
//                         <span className="font-medium text-gray-800 dark:text-white">
//                             {tokenBalance} SCO
//                         </span>
//                     </div>
//                     <div className="flex items-center space-x-2">
//                         <span className="text-gray-500 dark:text-gray-400">
//                             {getShortAddress(account)}
//                         </span>
//                     </div>
//                 </div>
//             ) : (
//                 <Button onClick={handleConnect} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
//                     Connect Wallet
//                 </Button>
//             )}
//         </div>
//     );
// };

// export default ConnectWalletButton;