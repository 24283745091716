import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'flowbite-react';
import { useBlockchain } from '../hooks/useBlockchain';

const StakeConfirmation = ({ amount, duration, projectAddress, handleNextStep }) => {
    const { account, stakeTokens } = useBlockchain();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [stakingSuccessful, setStakingSuccessful] = useState(false);

    const handleStakeTokens = async () => {
        setLoading(true);
        setError(null);
        try {
            var response = await stakeTokens(amount, projectAddress);
            setStakingSuccessful(response);
            pollStakingStatus();
        } catch (err) {
            setError(err.message);
        }
    };

    const pollStakingStatus = () => {
        const intervalId = setInterval(async () => {
            try {
                // Add logic to check the staking status here
                // For example, you might call a function to fetch the updated balance or staking status
                const status = true; // Replace with actual status check logic
                if (status) {
                    setStakingSuccessful(true);
                    clearInterval(intervalId);
                    setLoading(false);
                }
            } catch (err) {
                setError(err.message);
                clearInterval(intervalId);
                setLoading(false);
            }
        }, 3000); // Poll every 3 seconds
    };

    return (
        <div className="flex-wrap items-center gap-2 pt-2">
            {loading ? (
                <Spinner aria-label="Loading" />
            ) : (
                <>
                    {error && <p className="text-red-500">{error}</p>}
                    {!stakingSuccessful && <Button onClick={handleStakeTokens}>Stake Tokens</Button>}
                    {stakingSuccessful && (
                        <>
                            <p className="text-green-500">Staking successful!</p>
                            <Button onClick={handleNextStep}>Next</Button>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default StakeConfirmation;
