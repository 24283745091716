import React, { useState, useEffect } from "react";
import { Button } from "flowbite-react";

const StakeDetails = ({
  amount,
  setAmount,
  duration,
  setDuration,
  handleAmountChange,
  handleNextStep,
  tokenBalance,
}) => {
  const [totalCashout, setTotalCashout] = useState(0);
  const [totalReward, setTotalReward] = useState(0);
  const defaultDuration = { duration: 365, rate: 7 };

  useEffect(() => {
    let treward =
      (Number.parseFloat(amount.toString()) / 100) *
      Number.parseFloat(duration.rate.toString());
    setTotalReward(treward);
    let tcashout = Number.parseFloat(amount.toString()) + treward;
    setTotalCashout(tcashout);
  }, [amount, duration]);

  return (
    <div>
      <h2 className="text-4xl font-extrabold dark:text-white">
        Total Cashout: {totalCashout.toFixed(4)}
      </h2>
      <h2 className="text-4xl font-extrabold dark:text-white">
        Total Reward: {totalReward.toFixed(4)}
      </h2>
      <h3 className="text-xl font-medium text-gray-900 dark:text-white">
        Stake Amount : {amount}
      </h3>
      <h3 className="text-xl font-medium text-gray-900 dark:text-white">
        Stake Duration : {defaultDuration.duration} days
      </h3>
      <h3 className="text-xl font-medium text-gray-900 dark:text-white">
        Remaining Token Balance : {tokenBalance - amount}
      </h3>
      <div>
        <form className="flex max-w-72 py-2">
          <label className="sr-only mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Your Email
          </label>
          <div className="relative w-full">
            <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3.5">
              <svg
                className="size-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 2a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1M2 5h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Zm8 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
                />
              </svg>
            </div>
            <input
              onChange={(e) => {
                if (Number.parseFloat(e.target.value) <= 0) {
                  setAmount(1);
                } else {
                  setAmount(e.target.value);
                }
              }}
              type="number"
              id="currency-input"
              className="z-20 block w-full rounded-s-lg border border-e-2 border-gray-300 border-e-gray-50 bg-gray-50 p-2.5 ps-10 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:border-e-gray-700  dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500"
              placeholder="Enter amount"
              required
            />
          </div>
          <button
            id="dropdown-currency-button"
            data-dropdown-toggle="dropdown-currency"
            className="z-10 inline-flex shrink-0 items-center rounded-e-lg border border-gray-300 bg-gray-100 px-4 py-2.5 text-center text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-700"
            type="button"
          >
            SCO
          </button>
        </form>
        <div className="flex flex-wrap gap-2">
          {/* <Button
            color={duration.duration === 90 ? "teal" : "light"}
            onClick={() => {
              setDuration({ duration: 90, rate: 3 });
            }}
          >
            90 days
          </Button>
          <Button
            color={duration.duration === 150 ? "teal" : "light"}
            onClick={() => {
              setDuration({ duration: 150, rate: 4 });
            }}
          >
            150 days
          </Button>
          <Button
            color={duration.duration === 210 ? "teal" : "light"}
            onClick={() => {
              setDuration({ duration: 210, rate: 5 });
            }}
          >
            210 days
          </Button>
          <Button
            color={duration.duration === 270 ? "teal" : "light"}
            onClick={() => {
              setDuration({ duration: 270, rate: 6 });
            }}
          >
            270 days
          </Button>
          <Button
            color={duration.duration === 365 ? "teal" : "light"}
            onClick={() => {
              setDuration({ duration: 365, rate: 7 });
            }}
          >
            365 days
          </Button> */}
        </div>
      </div>
      <div className="w-full pt-2">
        <Button
          disabled={tokenBalance - amount < 0}
          onClick={() => {
            if (tokenBalance - amount < 0 || amount < 1000) {
              return;
            }
            handleNextStep();
          }}
        >
          Next
        </Button>
        {tokenBalance - amount < 0 && (
          <p className="text-red-500">You do not have enough SCO tokens.</p>
        )}
      </div>
    </div>
  );
};

export default StakeDetails;
