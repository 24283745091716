import React from 'react';
import { Card, Checkbox, Spinner, Table } from 'flowbite-react';
import { useBlockchain } from '../hooks/useBlockchain';
import { useQuery } from 'react-query';
import StakeModal from './StakeModal';
import Web3 from 'web3';
import { NavLink } from 'react-router-dom';

interface ProjectCardProps {
    key: string,
    projectAddress: string;
    projectData: {
        _id: string;
        id: string;
        name: string,
        description: string,
        amountStored: number,
        value: number,
        address: string,
        holders: number,
        minimumStake: number
    }
}

const ProjectCard: React.FC<ProjectCardProps> = ({ projectAddress, projectData }) => {
    const { getProjectDetails, formatToken } = useBlockchain();

    // const { data: project, error, isLoading } = useQuery(['projectDetails', projectAddress], () => getProjectDetails(projectAddress));
    const { data: project, error, isLoading } = useQuery(['projectDetails', projectAddress], () => () => {

    });

    if (isLoading) {
        return <Spinner aria-label="Loading project details" />;
    }

    // if (error || !project) {
    //     return <div>Error loading project.</div>;
    // }

    return (
        <Card key={projectData.id} className="max-w-sm">
            <img
                src={`https://api.sacone.nma.gm/projects/image/${projectData['images'][0]}`}
                alt={projectData.name}
                className="w-full h-48 object-cover" // Tailwind CSS classes
            />
            <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                {projectData.name}
            </h5>
            <div className="mb-5 mt-2.5">
                <p className="text-gray-700 dark:text-gray-400">
                    {projectData.description}
                </p>
            </div>
            <div className="">
                {/* <p className="text-gray-700 dark:text-gray-400">
                    {projectData.address}
                </p> */}
                <a href={`https://sepolia.etherscan.io/address/${projectData.address}`} target="_blank" className="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                    <svg className="w-6 h-6 text-white mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778" />
                    </svg>
                    View on Etherscan
                </a>

            </div>
            <div className="overflow-x-auto">
                <Table hoverable>
                    <Table.Head>
                        <Table.HeadCell>Minimum Stake</Table.HeadCell>
                        <Table.HeadCell>{formatToken(projectData.minimumStake)}</Table.HeadCell>
                    </Table.Head>
                    <Table.Head>
                        <Table.HeadCell>Minimum Lockup Period</Table.HeadCell>
                        <Table.HeadCell>90 days</Table.HeadCell>
                    </Table.Head>
                    <Table.Head>
                        <Table.HeadCell>Total Holders</Table.HeadCell>
                        <Table.HeadCell>{projectData.holders} ppl</Table.HeadCell>
                    </Table.Head>
                </Table>
            </div>
            <div className="flex items-center justify-between">
                <span className="text-3xl font-bold text-gray-900 dark:text-white">
                    {formatToken(Web3.utils.fromWei(projectData.amountStored, "ether"))}
                </span>
            </div>
            <div className="flex items-center justify-between">
                <NavLink to={`/project/${projectData._id}`} className="text-blue-600 dark:text-blue-500">
                    <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">View More</button>
                </NavLink>
                {/* <StakeModal projectAddress={projectData.address} /> */}

            </div>
        </Card >
    );
};

export default ProjectCard;
