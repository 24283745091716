import React, { useEffect, useState } from 'react';
import ProjectCard from '../components/ProjectCard';
import { useBlockchain } from '../hooks/useBlockchain';


const fetchProjectsFromAPI = async () => {
    // Replace with actual API call
    const response = await fetch('https://api.sacone.nma.gm/projects');
    const projectData = await response.json();
    return projectData;
};


const Market: React.FC = () => {
    const { connect, account, tokenBalance, fetchTokenBalance, checkAllowance, approveTokens, stakeTokens } = useBlockchain();
    const [projects, setProjects] = useState<any[]>([]);
    const [mock] = useState<boolean>(true);  // Set this to false to call the actual API

    useEffect(() => {
        fetchProjectsFromAPI().then((p: any[]) => {
            setProjects(p);
        });
    }, [account]);


    return (
        <>
            {projects.length < 1 && <><p className="text-4xl font-semibold text-gray-900 dark:text-white">No projects found yet...</p></>}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {projects.map((project) => (
                    <ProjectCard projectData={project} key={project.address} projectAddress={project.address} />
                ))}
            </div>
        </>
    );
};

export default Market;
