import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TabsNavbar from "./components/TopNavbar";
import { DarkThemeToggle } from "flowbite-react";
import { MetaMaskProvider } from "metamask-react";
import { QueryClient, QueryClientProvider } from "react-query";
import Market from "./pages/Market";
import Account from "./pages/Account";
import Community from "./pages/Community";
import Contact from "./pages/Contact";
import DocumentationPage from "./pages/Docs";
import { BlockchainProvider } from "./hooks/useBlockchain";
import ProjectPage from './pages/Project';

function App() {
  const queryClient = new QueryClient();
  return (
    <MetaMaskProvider>
      <BlockchainProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <main className="flex min-h-screen flex-col items-center justify-center gap-2 pt-20 dark:bg-gray-800">
              <TabsNavbar />
              {/* <div className="mt-8"> */}
              <Routes>
                <Route path="/" element={<Market />} />
                <Route path="/market" element={<Market />} />
                <Route path="/project/:id" element={<ProjectPage />} />
                <Route path="/account" element={<Account />} />
                <Route path="/community" element={<Community />} />
                <Route path="/about" element={<Contact />} />
                <Route path="/docs" element={<DocumentationPage />} />
              </Routes>
              {/* </div> */}
            </main>
          </Router>
        </QueryClientProvider>
      </BlockchainProvider>
    </MetaMaskProvider>
  );
}

export default App;
